export const MAIN_APP_URL = "https://app.marginly.com";
export const POOLS_URL = "https://marginly.com/pools/arbitrum";
export const DISCORD_URL = "https://discord.com/invite/tCTYwTzM8h";
export const TELEGRAM_URL = "https://t.me/marginly_official";
export const DASHBOARD_URL = "https://dashboard.marginly.com/";
export const AUDIT_REPORT_URL =
  "https://github.com/eq-lab/marginly/blob/main/audit/Quantstamp-marginly-final-report.pdf";
export const ABOUT_US_URL = "mailto:team@marginly.com";
export const EARN_URL = "https://app.marginly.com/mainnet/earn";
export const FARM_URL = "https://app.marginly.com/mainnet/farm";
export const BLOG_URL = "https://blog.marginly.com/";
