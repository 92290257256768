"use client";
import T from "@marginly/ui/components/typography";
import React, { useState } from "react";

import { QAUnit } from "./styled";

const QaItem = ({
  title,
  text,
}: {
  title: string;
  text: React.JSX.Element | string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <QAUnit
      open={open}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className="qa-title">
        <T headerS>{title}</T>
        <img src="/images/arrow-down.svg" alt="" className="arrow" />
      </div>
      <div className="qa-text">
        <T secondary as="div">
          {text}
        </T>
      </div>
    </QAUnit>
  );
};

export default QaItem;
