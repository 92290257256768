import styled from "styled-components";

export const QABlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  table {
    margin: 24px 0 0;
    padding: 2px;
    border-collapse: collapse;
    isolation: isolate;
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 2px solid var(--fill-secondary, rgba(204, 187, 184, 0.16));
      border-radius: 12px;
      z-index: -1;
    }
  }
  tr + tr {
    border-top: 2px solid var(--fill-secondary, rgba(204, 187, 184, 0.16));
  }
  td {
    padding: 6px 14px;
  }
  td:nth-child(2) {
    border-left: 2px solid var(--fill-secondary, rgba(204, 187, 184, 0.16));
  }
  .headerL {
    text-align: center;
  }
`;
