"use client";
import T from "@marginly/ui/components/typography";
import React from "react";

import Space from "../common/space";
import QaItem from "./qa-item";
import { QABlock } from "./styled";

const QA_DATA = [
  {
    title: "What is leveraged yield farming?",
    text: "Leveraged yield farming involves borrowing an LRT (Liquid Restaking Token) of your choice to buy Pendle PT (Principal Token) tokens on the same LRT. You hold PT tokens until maturity to secure high implied APY (Annual Percentage Yield) numbers.",
  },
  {
    title: "Who provides the liquidity?",
    text: "LRT holders who want to earn an additional passive interest rate and points on their liquidity.",
  },
  {
    title: "Why is the APY so high?",
    text: "The APY is high because people farm points on Pendle and pay ETH for farming. These ETH cash flows make the yield on Pendle PT tokens high. Marginly helps you capture this yield with leverage.",
  },
  {
    title: "Will my money be safe?",
    text: "Leveraged farming is associated with certain risks. We recommend reading our recent blog post, which extensively covers this subject matter. As for smart contract risk, Marginly has undergone two audits with Quantstamp.",
  },
];

const QaSection = () => {
  return (
    <QABlock>
      <T headerL>Questions &amp; Answers</T>
      <Space height={38} heightMobile={38} />
      {QA_DATA.map(({ title, text }) => (
        <QaItem title={title} text={text} key={title} />
      ))}
    </QABlock>
  );
};

export default QaSection;
