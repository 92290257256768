import styled from "styled-components";

export const QAUnit = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 26px;
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-elevated, #fff);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  padding: var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
  cursor: pointer;
  min-height: 80px;
  max-height: ${({ open }) => (open ? "500px" : "")};
  transition: max-height 0.9s ease-out, background 300ms ease-out;

  &:hover {
    background: var(--fill-secondary-hover, rgba(178, 162, 162, 0.32));
  }
  .qa-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .qa-text {
    display: ${({ open }) => (open ? "block" : "none")};
    a {
      color: var(--color-opaque-blue, blue);
      text-decoration: underline;
    }
  }
  .arrow {
    transition: transform 0.2s ease-out;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
  }
  .secondary {
    color: var(--text-secondary, rgba(60, 62, 66, 0.64));
  }
`;
