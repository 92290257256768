"use client";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: 1344px;
  .action,
  .headerM,
  .headerS,
  .headerL {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }

  @media (max-width: 1440px) {
    max-width: 1184px;
  }

  @media (max-width: 1024px) {
    max-width: 884px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
