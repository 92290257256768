"use client";

import Button from "@marginly/ui/components/button";
import TextField from "@marginly/ui/components/input/text-field";
import T from "@marginly/ui/components/typography";
import { ChangeEvent, useCallback, useState } from "react";

import {
  AUDIT_REPORT_URL,
  BLOG_URL,
  DASHBOARD_URL,
  DISCORD_URL,
  TELEGRAM_URL,
} from "@/constants/links";
import { useRx } from "@/contexts/rx";
import {
  trackFooterLinkClick,
  trackSocialClick,
  trackSubscribeNewsletterClick,
  trackSubscribeNewsletterError,
  trackSubscribeNewsletterSuccess,
} from "@/util/tracking";

import { Container } from "../container.styled";
import Space from "../space";
import {
  FooterBottom,
  FooterLinks,
  FooterSocialButton,
  FooterSocials,
  FooterWrapper,
  Form,
  Subscribe,
} from "./styled";

const API_URL = "https://api.marginly.com/api";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

export const validateEmail = (email: string) => {
  return emailRegex.test(email.toLowerCase());
};

const Footer = () => {
  const [email, setEmail] = useState("");
  const [pending, setPending] = useState(false);
  const { error$, notification$ } = useRx();

  const handleEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [setEmail],
  );

  const handleSubmit = useCallback<React.FormEventHandler>(
    async (e) => {
      e.preventDefault();
      if (!validateEmail(email)) {
        notification$.next({
          message: "Enter correct email address",
        });
        console.error("Email is invalid:", email);
        return;
      }
      if (pending) return;

      try {
        trackSubscribeNewsletterClick();
        setPending(true);

        const response = await fetch(`${API_URL}/users`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            accountIds: [],
          }),
        });

        if (response.ok) {
          notification$.next({
            message: "Subscribed successfully!",
          });
          trackSubscribeNewsletterSuccess();
        } else {
          notification$.next({
            message: "Subscription failed",
          });
          trackSubscribeNewsletterError();
        }
      } catch (e) {
        e instanceof Error && error$.next(e);
      } finally {
        setPending(false);
      }
    },
    [email, pending, notification$, error$],
  );

  return (
    <Container>
      <FooterWrapper>
        <Subscribe>
          <T headerS className="header">
            Subscribe to our newsletter
          </T>
          <Form>
            <TextField
              placeholder="Your email"
              onChange={handleEmailChange}
              value={email}
            />
            <Button
              lg
              icon
              elevated
              className="button"
              onClick={handleSubmit}
              pending={pending}
            >
              <img src="/images/send.svg" alt="" />
            </Button>
          </Form>
        </Subscribe>
        <Space height={48} heightMobile={48} />

        <FooterBottom>
          <FooterSocials>
            <FooterSocialButton
              href="https://twitter.com/marginlycom"
              rel="noreferrer noopener"
              onClick={() => trackSocialClick("twitter")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_7927_20773)">
                  <mask
                    id="mask0_7927_20773"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M17.7512 3H20.818L14.1179 10.6246L22 21H15.8284L10.9946 14.7074L5.46359 21H2.39494L9.5613 12.8446L2 3H8.32828L12.6976 8.75169L17.7512 3ZM16.6748 19.1723H18.3742L7.4049 4.73169H5.58133L16.6748 19.1723Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_7927_20773)">
                    <rect width="24" height="24" fill="black" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_7927_20773">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </FooterSocialButton>

            <FooterSocialButton
              href={DISCORD_URL}
              rel="noreferrer noopener"
              onClick={() => trackSocialClick("discord")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1528_111395"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="5"
                  width="28"
                  height="21"
                >
                  <path
                    d="M25.2745 7.03124C23.5361 6.23615 21.6899 5.65668 19.7628 5.33325C19.5202 5.75101 19.2506 6.317 19.062 6.76172C17.0123 6.46524 14.9774 6.46524 12.9559 6.76172C12.7673 6.317 12.4843 5.75101 12.2552 5.33325C10.3146 5.65668 8.4684 6.23615 6.74211 7.03124C3.2518 12.1791 2.30847 17.2057 2.78013 22.1649C5.09802 23.8494 7.33508 24.8736 9.53304 25.5475C10.0721 24.8197 10.5572 24.0381 10.975 23.2161C10.1799 22.9196 9.42523 22.5557 8.69752 22.1244C8.88619 21.9898 9.07486 21.8415 9.25005 21.6933C13.6433 23.7012 18.4017 23.7012 22.741 21.6933C22.9296 21.8415 23.1048 21.9898 23.2935 22.1244C22.5658 22.5557 21.8111 22.9196 21.016 23.2161C21.4338 24.0381 21.919 24.8197 22.4579 25.5475C24.6546 24.8736 26.9051 23.8494 29.2109 22.1649C29.7903 16.4241 28.2931 11.438 25.2745 7.03124ZM11.5814 19.1058C10.2607 19.1058 9.18264 17.9065 9.18264 16.4376C9.18264 14.9687 10.2338 13.7693 11.5814 13.7693C12.9155 13.7693 14.0071 14.9687 13.9801 16.4376C13.9801 17.9065 12.9155 19.1058 11.5814 19.1058ZM20.4366 19.1058C19.1159 19.1058 18.0364 17.9065 18.0364 16.4376C18.0364 14.9687 19.0889 13.7693 20.4366 13.7693C21.7707 13.7693 22.8623 14.9687 22.8353 16.4376C22.8353 17.9065 21.7842 19.1058 20.4366 19.1058Z"
                    fill="currentColor"
                  />
                </mask>
                <g mask="url(#mask0_1528_111395)">
                  <rect width="32" height="32" fill="currentColor" />
                </g>
              </svg>
            </FooterSocialButton>
            <FooterSocialButton
              href={TELEGRAM_URL}
              rel="noreferrer noopener"
              onClick={() => trackSocialClick("telegram")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3.11171 11.8504L13.8337 7.43272C14.8921 6.97255 18.4814 5.5 18.4814 5.5C18.4814 5.5 20.1381 4.85576 20 6.42034C19.954 7.06458 19.5858 9.31942 19.2177 11.7583L18.0673 18.983C18.0673 18.983 17.9752 20.0414 17.193 20.2255C16.4107 20.4096 15.1222 19.5813 14.8921 19.3972C14.708 19.2591 11.4408 17.1884 10.2444 16.176C9.92225 15.8999 9.55411 15.3477 10.2904 14.7034C11.947 13.1849 13.9257 11.2982 15.1222 10.1017C15.6744 9.54951 16.2266 8.26103 13.9257 9.82561L7.43732 14.1972C7.43732 14.1972 6.70105 14.6574 5.32053 14.2433C3.94002 13.8291 2.32942 13.2769 2.32942 13.2769C2.32942 13.2769 1.225 12.5866 3.11171 11.8504Z"
                  fill="black"
                />
              </svg>
            </FooterSocialButton>
            <FooterSocialButton
              href="https://marginly.medium.com"
              rel="noreferrer noopener"
              onClick={() => trackSocialClick("medium")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_12_2345)">
                  <mask
                    id="mask0_12_2345"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M13.7889 12.0001C13.7889 15.3138 11.1209 18 7.82988 18C4.5389 18 1.87068 15.3132 1.87068 12.0001C1.87068 8.68705 4.5387 6 7.82988 6C11.1211 6 13.7889 8.68644 13.7889 12.0001ZM20.3261 12.0001C20.3261 15.1192 18.9921 17.6487 17.3465 17.6487C15.7009 17.6487 14.3669 15.1192 14.3669 12.0001C14.3669 8.881 15.7007 6.35147 17.3463 6.35147C18.9919 6.35147 20.3261 8.88019 20.3261 12.0001ZM23 12.0001C23 14.794 22.5309 17.0604 21.9521 17.0604C21.3732 17.0604 20.9043 14.7947 20.9043 12.0001C20.9043 9.20555 21.3734 6.93982 21.9521 6.93982C22.5307 6.93982 23 9.20534 23 12.0001Z"
                      fill="var(--icon-primary)"
                    />
                  </mask>
                  <g mask="url(#mask0_12_2345)">
                    <rect width="24" height="24" fill="var(--icon-primary)" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_12_2345">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </FooterSocialButton>
            <FooterSocialButton
              href="https://github.com/eq-lab/Marginly"
              rel="noreferrer noopener"
              onClick={() => trackSocialClick("github")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1528_111399"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="2"
                  width="28"
                  height="28"
                >
                  <g clipPath="url(#clip0_1528_111399)">
                    <path
                      d="M16.0003 2.66675C23.367 2.66675 29.3337 8.63341 29.3337 16.0001C29.3329 18.7938 28.4562 21.5169 26.8267 23.7861C25.1973 26.0554 22.8972 27.7565 20.2503 28.6501C19.5837 28.7834 19.3337 28.3667 19.3337 28.0167C19.3337 27.5667 19.3503 26.1334 19.3503 24.3501C19.3503 23.1001 18.9337 22.3001 18.4503 21.8834C21.417 21.5501 24.5337 20.4167 24.5337 15.3001C24.5337 13.8334 24.017 12.6501 23.167 11.7167C23.3003 11.3834 23.767 10.0167 23.0337 8.18341C23.0337 8.18341 21.917 7.81675 19.367 9.55008C18.3003 9.25008 17.167 9.10008 16.0337 9.10008C14.9003 9.10008 13.767 9.25008 12.7003 9.55008C10.1503 7.83341 9.03366 8.18341 9.03366 8.18341C8.30033 10.0167 8.76699 11.3834 8.90033 11.7167C8.05033 12.6501 7.53366 13.8501 7.53366 15.3001C7.53366 20.4001 10.6337 21.5501 13.6003 21.8834C13.217 22.2167 12.867 22.8001 12.7503 23.6667C11.9837 24.0167 10.067 24.5834 8.86699 22.5667C8.61699 22.1667 7.86699 21.1834 6.81699 21.2001C5.70033 21.2167 6.36699 21.8334 6.83366 22.0834C7.40033 22.4001 8.05033 23.5834 8.20033 23.9667C8.46699 24.7167 9.33366 26.1501 12.6837 25.5334C12.6837 26.6501 12.7003 27.7001 12.7003 28.0167C12.7003 28.3667 12.4503 28.7667 11.7837 28.6501C9.12806 27.7661 6.81822 26.0684 5.18181 23.7978C3.5454 21.5272 2.66552 18.7989 2.66699 16.0001C2.66699 8.63341 8.63366 2.66675 16.0003 2.66675Z"
                      fill="currentColor"
                    />
                  </g>
                </mask>
                <g mask="url(#mask0_1528_111399)">
                  <rect width="32" height="32" fill="currentColor" />
                </g>
                <defs>
                  <clipPath id="clip0_1528_111399">
                    <rect
                      width="26.6667"
                      height="26.6667"
                      fill="currentColor"
                      transform="translate(2.66699 2.66675)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </FooterSocialButton>
          </FooterSocials>

          <FooterLinks>
            <a
              href={AUDIT_REPORT_URL}
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => trackFooterLinkClick("quantstamp")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_258_6008)">
                  <mask
                    id="mask0_258_6008"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M19.3185 12C19.3185 10.591 18.9111 9.2041 18.1333 8.02377L21 5.1541L18.8519 3L15.9852 5.86967C13.5704 4.29098 10.4444 4.29098 8.02963 5.86967L5.15556 3L3 5.1541L5.86667 8.02377C4.28889 10.4434 4.28889 13.5639 5.86667 15.9836L3 18.8459L5.15556 21L8.02222 18.1303C10.437 19.709 13.563 19.709 15.9778 18.1303L18.8444 21L21 18.8459L18.1333 15.9762C18.9111 14.7959 19.3259 13.4164 19.3185 12ZM7.73333 12C7.73333 10.259 8.78519 8.68771 10.4 8.03853C12.0148 7.38934 13.8667 7.78033 15.0741 9.03443C16.2815 10.2885 16.6074 12.1549 15.8889 13.741L13.0741 10.923L10.9259 13.077L13.7407 15.8951C12.4222 16.4852 10.8889 16.3746 9.67407 15.5852C8.46667 14.7959 7.73333 13.4459 7.73333 12Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_258_6008)">
                    <rect
                      width="24"
                      height="24"
                      fill="currentColor"
                      fillOpacity="0.64"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_258_6008">
                    <rect width="24" height="24" fill="currentColor" />
                  </clipPath>
                </defs>
              </svg>

              <T action secondary>
                Audited by Quantstamp
              </T>
            </a>
            <a
              href={DASHBOARD_URL}
              onClick={() => trackFooterLinkClick("risk dashboard")}
              target="_blank"
              rel="noreferrer"
            >
              <T action secondary>
                Risk Dashboard
              </T>
            </a>
            <a
              href="terms-and-conditions.pdf"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackFooterLinkClick("terms and conditions")}
            >
              <T action secondary>
                Terms & conditions
              </T>
            </a>
            <a
              href="privacy-policy.pdf"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackFooterLinkClick("privacy policy")}
            >
              <T action secondary>
                Privacy policy
              </T>
            </a>
            <a
              href="https://docs.marginly.com/"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackFooterLinkClick("documents")}
            >
              <T action secondary>
                Documents
              </T>
            </a>
            <a
              href={BLOG_URL}
              target="_blank"
              rel="noreferrer"
              onClick={() => trackFooterLinkClick("blog")}
            >
              <T action secondary>
                Blog
              </T>
            </a>
          </FooterLinks>
        </FooterBottom>
      </FooterWrapper>
    </Container>
  );
};

export default Footer;
