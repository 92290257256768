import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  border-radius: 56px 56px 0px 0px;
  backdrop-filter: blur(12px);
  padding: var(--spacing-space-32, 32px) var(--spacing-space-32, 32px)
    var(--spacing-space-48, 48px) var(--spacing-space-32, 32px);
  // @media (max-width: 769px) {
  //   margin: 0 -24px;
  // }
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FooterLinks = styled.div`
  gap: 32px 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  button,
  a {
    text-decoration: none;
    letter-spacing: 0.01em;
    border: 0;
    outline: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--text-secondary);
    height: 38px;
  }
  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    margin-top: 6px;
    a {
      padding: 0;
    }
    button,
    a {
      height: 30px;
    }
  }
`;

export const FooterSocialButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  color: #000;
  width: 64px;
  height: 64px;
  padding: 2px;
  border-radius: 8px;
  transition: 300ms ease-out;
  &:hover {
    mix-blend-mode: multiply;
  }
  &:active {
    transform: scale(0.95);
  }
  @media (max-width: 769px) {
    border-radius: 16px !important;
  }
`;

export const Subscribe = styled.div`
  border-radius: var(--rounding-radius-xxl, 32px);
  background: #fff;
  padding: var(--spacing-space-24, 24px) var(--spacing-space-24, 24px)
    var(--spacing-space-24, 24px) var(--spacing-space-40, 40px);
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(51, 20, 0, 0.04),
    0px 8px 24px 0px rgba(51, 20, 0, 0.08);
  & > * {
    flex: 1 1 50%;
  }
  .header {
    color: var(--text-primary-on-dark, #000);
  }
  .button {
    max-width: 64px;
    padding: 2px;
    background: #9fe;
    box-shadow: none;
    &:hover {
      background: #5cdfd4;
    }
  }
  .input__area {
    display: flex;
    align-items: center;
  }
  .input__area .input__hint {
    color: var(--invert-secondary, rgba(242, 237, 235, 0.48));
  }
  @media (max-width: 769px) {
    flex-direction: column;
    padding: 24px;
    gap: 32px;
    align-items: center;
    & > * {
      text-align: center;
    }
    .header {
      padding: 0 24px;
    }
  }
`;

export const Form = styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon.button {
      width: 64px;
      padding: 2px;
    }
    input {
      color: #000;
      caret-color: #000;
    }
    @media (max-width: 769px) {
      width: 100%;
      text-align: left;
    }
  }
`;
